<template>
  <div class="w-full max-w-3xl py-20 bg-white mx-auto">
    <Back route="/inbox" title="inbox.back_to_inbox" class="my-20" />
    <div v-if="message">
      <h4 class="mt-5 font-semibold leading-tight">{{ messageSubject }}</h4>
      <div class="mt-7 mb-20 flex justify-between text-black">
        <p class="self-center text-sm">{{ sentAt }}</p>
        <HMButton
          color="transparent"
          size="inline"
          textSize="small"
          class="font-semibold"
          @click="archiveButtonAction"
        >
          <div class="flex">
            <Icon class="mr-8" :iconClass="archiveButtonIcon" />
            <p>
              {{
                isArchived
                  ? $t('inbox.unarchive_btn')
                  : $t('inbox.archive_move_btn')
              }}
            </p>
          </div>
        </HMButton>
      </div>
      <Markdown :source="messageBody" />
    </div>
    <p
      v-else
      class="font-bold text-lg sm:text-xl md:text-3xl text-center mt-50"
    >
      {{ $t('inbox.message_not_found') }}
    </p>
  </div>
</template>

<script>
import Markdown from '../../common/Markdown'
import { mapGetters, mapActions } from 'vuex'
import _get from 'lodash/get'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { MESSAGE_STATUS } from '@platform-shared/constants'

export default {
  components: { Markdown },
  computed: {
    ...mapGetters('inbox', ['messageById']),
    messageId() {
      return this.$route.params.messageId
    },
    message() {
      return this.messageById(this.messageId)
    },
    messageSubject() {
      return _get(this.message, 'subject')
    },
    sentAt() {
      if (this.message)
        return format(parseISO(this.message.sentAt), "MMM d 'at' h:mm b")
    },
    messageBody() {
      return _get(this.message, 'body', '')
    },
    archiveButtonIcon() {
      return _get(this.message, 'status') === MESSAGE_STATUS.ARCHIVE
        ? 'fas fa-arrow-circle-up'
        : 'far fa-folder-open'
    },
    isArchived() {
      return _get(this.message, 'status') === MESSAGE_STATUS.ARCHIVE
    },
  },
  methods: {
    ...mapActions('inbox', ['markAsRead', 'archive', 'unarchive']),
    archiveMessage() {
      this.archive(this.messageId)
    },
    unarchiveMessage() {
      this.unarchive(this.messageId)
    },
    archiveButtonAction() {
      if (this.message.status === MESSAGE_STATUS.ARCHIVE)
        this.unarchiveMessage()
      else this.archiveMessage()
    },
  },
  mounted() {
    if (this.message.status === MESSAGE_STATUS.UNREAD) {
      this.markAsRead(this.messageId)
    }
  },
}
</script>

<style></style>
