<template>
  <div class="w-full max-w-3xl bg-white mx-auto">
    <Back
      :route="{ name: 'inbox' }"
      title="inbox.back_to_inbox"
      class="my-20"
    />
    <h2 class="mt-20">{{ $t('inbox.archive') }}</h2>
    <p
      v-if="!archivedMessages.length"
      class="mt-8 pt-8 border-t border-grey-200"
    >
      {{ $t('inbox.no_messages') }}
    </p>
    <MessageListItem
      v-for="message in archivedMessages"
      :key="`msg-${message.id}`"
      :message="message"
      hideIndicator
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MessageListItem from './MessageListItem'

export default {
  components: { MessageListItem },
  computed: {
    ...mapGetters('inbox', ['archivedMessages']),
  },
}
</script>

<style></style>
