<template>
  <router-link
    class="flex items-center justify-start border-b border-grey-200 pt-10 pb-14"
    :to="{ name: 'message-details', params: { messageId: message.id } }"
    role="group"
    :aria-label="$t('inbox.message')"
  >
    <Icon
      class="flex flex-col justify-center mr-7 text-white self-start mt-7"
      :class="{ 'text-blue-500': isUnread }"
      iconClass="fas fa-circle text-xxs"
      :aria-label="
        isUnread
          ? $t('inbox.unread')
          : $t('learn.landing.history.marked_as_read')
      "
    />
    <div class="mr-12 flex-1">
      <p class="font-semibold">
        {{ message.subject }}
      </p>
      <VueMarkdown
        class="text-sm text-grey-500 pt-3"
        :source="previewText | T"
      />
    </div>
    <p
      class="block text-sm leading-loose mr-12 text-black whitespace-no-wrap self-start"
    >
      {{ sentAt }}
    </p>
    <Icon class="block self-start mt-2" iconClass="fas fa-chevron-right" />
  </router-link>
</template>

<script>
import { MESSAGE_STATUS } from '@platform-shared/constants'
import format from 'date-fns/format'
import VueMarkdown from 'vue-markdown'
import _get from 'lodash/get'

export default {
  components: { VueMarkdown },
  props: {
    message: {
      type: Object,
      required: true,
    },
    hideIndicator: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sentAt() {
      return format(new Date(_get(this.message, 'sentAt')), 'MMM d')
    },
    isUnread() {
      return _get(this.message, 'status') === MESSAGE_STATUS.UNREAD
    },
    previewText() {
      return _get(this.message, 'body', '').replace(/\r?\n|\r/g, ' ')
    },
  },
}
</script>

<style></style>
