<template>
  <div class="w-full max-w-3xl py-20 bg-white mx-auto">
    <h2 class="mt-5 mb-16">{{ $t('inbox.title') }}</h2>
    <div class="flex justify-between text-sm md:text-base mb-2">
      <p class="self-center text-sm">
        {{ $t('inbox.unread_count', [unreadMessagesCount]) }}
      </p>
      <router-link
        class="p-8 text-sm font-semibold flex"
        :to="{ name: 'archive' }"
      >
        <Icon iconClass="far fa-folder-open mr-8" />
        <span>{{ $t('inbox.archive') }}</span>
      </router-link>
    </div>
    <p v-if="!messages.length" class="mt-8 pt-8 border-t border-grey-200">
      {{ $t('inbox.no_messages') }}
    </p>
    <MessageListItem
      v-for="message in messages"
      :key="`msg-${message.id}`"
      :message="message"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MessageListItem from './MessageListItem'

export default {
  components: { MessageListItem },
  computed: {
    ...mapGetters('inbox', ['messages', 'unreadMessagesCount']),
  },
}
</script>

<style></style>
